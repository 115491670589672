import { Component, OnInit, ViewChild } from '@angular/core';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Card } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import * as CardSelectors from '../../store/selectors/card.selectors';
import * as CardActions from '../../store/actions/card.actions';
import { AddCardDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cards',
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.scss',
})
export class CardsComponent implements OnInit {
  cards$?: Observable<Card[] | undefined> = this.store.select(CardSelectors.selectCards);
  isLoading$?: Observable<boolean> = this.store.select(CardSelectors.selectCardIsLoading);
  count$: Observable<number | undefined> = this.store.select(CardSelectors.selectCardCount);
  displayedColumns: string[] = ['data', 'type', 'user', 'notes', 'timestampCreated', 'actions'];
  filters: any;
  dataSource = new MatTableDataSource<Card>();
  private destroy$ = new Subject<void>();
  private searchSubject = new Subject<string>();

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(this.destroy$),
    ).subscribe(params => {
      this.filters = {
        ...params,
        search: params['search'] || undefined,
        pageSize: +params['pageSize'] || 10,
        pageOffset: +params['pageOffset'] || 0,
      };
      this.updateFilters(this.filters);
    });

    this.store.select(CardSelectors.selectCardFilters).pipe(
      takeUntil(this.destroy$),
    ).subscribe(filters => {
      this.filters = filters;
      this.sharedService.updateParams(filters);
    });

    this.searchSubject.pipe(
      debounceTime(500),
      takeUntil(this.destroy$),
    ).subscribe(searchValue => {
      if (this.filters.search !== searchValue) {
        this.filters.search = searchValue;
        this.updateFilters(this.filters);
      }
    });
  }

  onPageFiltersChange(event: any): void {
    this.filters = { ...this.filters, pageSize: event.pageSize, pageOffset: event.pageIndex };
    this.updateFilters(this.filters);
  }

  updateSearchValue(searchValue: string): void {
    this.searchSubject.next(searchValue);
  }

  updateFilters(filters: any): void {
    this.sharedService.updateParams(filters);
    this.store.dispatch(CardActions.updateCardFilters({ filters }));
  }

  showManageCardDialog(card?: Card): void {
    this.dialog.open(AddCardDialogComponent, {
      width: '500px',
      data: { ...card },
    });
  }

  showDeleteDialog(card: Card): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Delete Card',
        message: `Are you sure you want to delete card \'${card.data}\'?`,
        okText: 'Delete'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(CardActions.deleteCard({ id: card.id! }));
      }
    });
  }


  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faTrash = faTrash;
}
