<div class="page accounts">
  <ng-container *ngIf="{
        accounts: accounts$ | async,
        loading: isLoading$ | async
    } as data">
  <div class="container">
    <div class="header">
      <div class="title">Accounts</div>
      <div class="page-actions">
        <button mat-raised-button color="primary" (click)="showAddAccountDialog()">
          <fa-icon class="icon" [icon]="faPlusCircle"></fa-icon>
          <span>New Account</span>
        </button>
      </div>
    </div>
    <div class="content">
      <div class="loader" *ngIf="data.loading; else accountsData">
        <mat-spinner diameter="50" color="primary"></mat-spinner>
      </div>
      <ng-template #accountsData>
        <div *ngIf="data.accounts">
          <search-input (searchInputChange)="filterAccounts($event)"></search-input>
          <table mat-table [dataSource]="dataSource" matSort matSortActive="displayName" matSortDirection="asc">

            <!-- Username Column -->
            <ng-container matColumnDef="displayName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by username/name">
                Username/Name
              </th>
              <td mat-cell *matCellDef="let account">{{ account.displayName }}</td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                  sortActionDescription="Sort by type">Type
              </th>
              <td mat-cell class="text-center" *matCellDef="let account">{{ getEnumValue(AccountType, account.type) }}
              </td>
            </ng-container>

            <!-- Timestamp Created Column -->
            <ng-container matColumnDef="timestampCreated">
              <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                  sortActionDescription="Sort by datetime created">Created At
              </th>
              <td mat-cell class="text-center"
                  *matCellDef="let account">{{ account.timestampCreated | date: 'MMM dd, YYYY HH:mm' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell class="text-center" *matHeaderCellDef></th>
              <td mat-cell class="text-center" *matCellDef="let account">
                <div class="text-sm">
                  <button class="icon-button" mat-icon-button title="Delete" (click)="showDeleteDialog(account)">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <!-- Column definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
                No Results
              </td>
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

        </div>
      </ng-template>
    </div>
  </div>
  </ng-container>
</div>
