import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, Observable, Subject, takeUntil } from 'rxjs';
import { Activity } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import * as ActivitySelectors from '../../store/selectors/activity.selectors';
import * as ActivityActions from '../../store/actions/activity.actions';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'activities',
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.scss',
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  activities$: Observable<Activity[] | undefined> = this.store.select(ActivitySelectors.selectActivities);
  isLoading$?: Observable<boolean> = this.store.select(ActivitySelectors.selectActivityIsLoading);
  displayedColumns: string[] = ['timestamp', 'event', 'user', 'site', 'controller', 'actions'];
  count$: Observable<number | undefined> = this.store.select(ActivitySelectors.selectActivityCount);
  filters: any;
  dataSource = new MatTableDataSource<Activity>();
  private destroy$ = new Subject<void>();
  private searchSubject = new Subject<string>();

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(this.destroy$),
    ).subscribe(params => {
      this.filters = {
        ...params,
        search: params['search'] || undefined,
        pageSize: +params['pageSize'] || 10,
        pageOffset: +params['pageOffset'] || 0,
      };
      this.updateFilters(this.filters);
    });

    this.store.select(ActivitySelectors.selectActivityFilters).pipe(
      takeUntil(this.destroy$),
    ).subscribe(filters => {
      this.filters = filters;
      this.sharedService.updateParams(filters);
    });

    // Listen for search value changes with debouncing
    this.searchSubject.pipe(
      debounceTime(500),
      takeUntil(this.destroy$),
    ).subscribe(searchValue => {
      if (this.filters.search !== searchValue) {
        this.filters.search = searchValue;
        this.updateFilters(this.filters);
      }
    });

  }

  onPageFiltersChange(event: any): void {
    this.filters = { ...this.filters, pageSize: event.pageSize, pageOffset: event.pageIndex };
    this.updateFilters(this.filters);
  }

  updateSearchValue(searchValue: string): void {
    this.searchSubject.next(searchValue);
  }

  updateFilters(filters: any): void {
    this.sharedService.updateParams(filters);
    this.store.dispatch(ActivityActions.updateActivityFilters({ filters }));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
