<div class="flex justify-between items-baseline">
  <h2 mat-dialog-title>{{ isNew ? 'Add New' : 'Edit' }} Controller</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon class="text-white">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="flex gap-4">
    <mat-form-field class="flex-1" appearance="fill">
      <mat-label>Name</mat-label>
      <div class="flex">
        <input matInput placeholder="Name" [(ngModel)]="controller.name" required>
      </div>
    </mat-form-field>
    <select-multiple-popup class="flex-1"
                           title="Select Type"
                           label="Type"
                           placeholder="Select Type"
                           [required]="true"
                           [multiple]="false"
                           [options]="['UHPPOTE UT0311-L0x TCP/IP Wiegand']"
                           [model]="controller.type"
                           (modelChange)="controller.type = $event.toString()"></select-multiple-popup>
    <mat-form-field class="flex-1" appearance="fill">
      <mat-label>Serial Number</mat-label>
      <div class="flex">
        <input matInput placeholder="Serial Number" [(ngModel)]="controller.serialNumber" required>
      </div>
    </mat-form-field>
  </div>

  <div class="flex gap-4">
    <mat-form-field class="flex-1" appearance="fill">
      <mat-label>IP Address</mat-label>
      <input matInput #addressInput="ngModel"
             [(ngModel)]="controller.address"
             placeholder="Address"
             [pattern]="ipPattern"
             required>
      <mat-error *ngIf="addressInput.invalid && (addressInput.dirty || addressInput.touched)">
        Please enter a valid IP Address.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="flex-1" appearance="fill">
      <mat-label>Gateway</mat-label>
      <input matInput #gatewayInput="ngModel"
             [(ngModel)]="controller.gateway"
             placeholder="Gateway"
             [pattern]="ipPattern"
             required>
      <mat-error *ngIf="gatewayInput.invalid && (gatewayInput.dirty || gatewayInput.touched)">
        Please enter a valid Gateway Address.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="flex-1" appearance="fill">
      <mat-label>Subnet Mask</mat-label>
      <input matInput #maskInput="ngModel"
             [(ngModel)]="controller.mask"
             placeholder="Mask"
             [pattern]="ipPattern"
             required>
      <mat-error *ngIf="maskInput.invalid && (maskInput.dirty || maskInput.touched)">
        Please enter a valid Mask Address.
      </mat-error>
    </mat-form-field>
  </div>

  <div class="flex">
    <mat-form-field class="flex-1" appearance="fill">
      <mat-label>Controller Notes</mat-label>
      <div class="flex">
        <textarea matInput placeholder="Controller Notes" [(ngModel)]="controller.notes"></textarea>
      </div>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [disabled]="loading" (click)="dialogRef.close()">Cancel</button>
  <button mat-button color="primary" [disabled]="loading" (click)="onSubmit()">
    <span class="font-semibold" *ngIf="!loading">{{ isNew ? 'Add' : 'Update' }}</span>
    <span class="flex gap-x-1" *ngIf="loading">{{ isNew ? 'Adding' : 'Saving' }}...
      <mat-spinner *ngIf="loading"
                   diameter="20"
                   color="accent"></mat-spinner>
    </span>
  </button>
</mat-dialog-actions>
