import { Component, OnInit, ViewChild } from '@angular/core';
import { faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { User } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import {
  AddUserDialogComponent,
  ConfirmDialogComponent,
  ConfirmDialogType
} from '@components';
import { debounceTime, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import * as UserSelectors from '../../store/selectors/user.selectors';
import * as UserActions from '../../store/actions/user.actions';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
})
export class UsersComponent implements OnInit {
  users$?: Observable<User[] | undefined> = this.store.select(UserSelectors.selectUsers);
  isLoading$?: Observable<boolean> = this.store.select(UserSelectors.selectUserIsLoading);
  count$: Observable<number | undefined> = this.store.select(UserSelectors.selectUserCount);
  displayedColumns: string[] = ['name', 'phone', 'createdByAccount.username', 'notes', 'timestampCreated', 'actions'];
  filters: any;
  dataSource = new MatTableDataSource<User>();
  private destroy$ = new Subject<void>();
  private searchSubject = new Subject<string>();

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(this.destroy$),
    ).subscribe(params => {
      this.filters = {
        ...params,
        search: params['search'] || undefined,
        pageSize: +params['pageSize'] || 10,
        pageOffset: +params['pageOffset'] || 0,
      };
      this.updateFilters(this.filters);
    });

    this.store.select(UserSelectors.selectUserFilters).pipe(
      takeUntil(this.destroy$),
    ).subscribe(filters => {
      this.filters = filters;
      this.sharedService.updateParams(filters);
    });

    // Listen for search value changes with debouncing
    this.searchSubject.pipe(
      debounceTime(500),
      takeUntil(this.destroy$),
    ).subscribe(searchValue => {
      if (this.filters.search !== searchValue) {
        this.filters.search = searchValue;
        this.updateFilters(this.filters);
      }
    });
  }

  onPageFiltersChange(event: any): void {
    this.filters = { ...this.filters, pageSize: event.pageSize, pageOffset: event.pageIndex };
    this.updateFilters(this.filters);
  }

  updateSearchValue(searchValue: string): void {
    this.searchSubject.next(searchValue);
  }

  updateFilters(filters: any): void {
    this.sharedService.updateParams(filters);
    this.store.dispatch(UserActions.updateUserFilters({ filters }));
  }

  showManageUserDialog(user?: User): void {
    this.dialog.open(AddUserDialogComponent, {
      width: '500px',
      data: { ...user },
    });
  }

  showDeleteDialog(user: User): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Delete User',
        message: `Are you sure you want to delete user \'${user.name}\'?`,
        okText: 'Delete',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(UserActions.deleteUser({ id: user.id! }));
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  protected readonly faEdit = faEdit;
  protected readonly faTrash = faTrash;
  protected readonly faPlusCircle = faPlusCircle;
}
