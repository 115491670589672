import { Component, OnInit, ViewChild } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { Controller } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/store';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import * as ControllerSelectors from '../../store/selectors/controller.selectors';
import * as ControllerActions from '../../store/actions/controller.actions';
import { faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AddControllerDialogComponent, ConfirmDialogComponent, ConfirmDialogType } from '@components';

@Component({
  selector: 'controllers',
  templateUrl: './controllers.component.html',
  styleUrl: './controllers.component.scss',
})
export class ControllersComponent implements OnInit {
  controllers$?: Observable<Controller[] | undefined>;
  isLoading$?: Observable<boolean>;
  updatingActivationId$?: Observable<number | undefined>;
  controllers?: Controller[];
  displayedColumns: string[] = ['name', 'serialNumber', 'onlineStatus', 'type', 'addresses', 'project', 'dateAddedToProject', 'notes', 'timestampCreated', 'actions'];
  searchValue?: string;
  dataSource = new MatTableDataSource<Controller>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.controllers$ = this.store.pipe(
      select(ControllerSelectors.selectControllers),
      tap(controllers => {
        if (!controllers) {
          this.store.dispatch(ControllerActions.loadControllers());
        }
      }),
      map(controllers => {
        this.controllers = controllers?.map(c => ({ ...c, notesCharLimit: 100 }));
        this.dataSource.data = this.controllers || [];
        return this.controllers;
      }),
    );
    this.isLoading$ = this.store.pipe(select(ControllerSelectors.selectControllerIsLoading));
    this.updatingActivationId$ = this.store.pipe(select(ControllerSelectors.selectUpdatingActivationId));
  }

  filterControllers(val: string | undefined): void {
    this.searchValue = val;
    if (!val) {
      this.dataSource.data = this.controllers || [];
      return;
    }
    this.dataSource.data = this.controllers?.filter(c => ['name', 'serialNumber', 'type', 'addresses', 'timestampCreated']
      .some(k => c[k as keyof Controller]?.toString().toLowerCase().includes(val.toLowerCase())
        || c.createdByAccount?.username?.toLowerCase().includes(val.toLowerCase()))) || [];
  }

  updateIsActive(controllerId: number): void {
    this.store.dispatch(ControllerActions.updateControllerActivation({ id: controllerId! }));
  }

  showManageControllerDialog(controller?: Controller): void {
    this.dialog.open(AddControllerDialogComponent, {
      width: '900px',
      data: { ...controller },
    });
  }

  showDeleteDialog(controller: Controller): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Delete Controller',
        message: `Are you sure you want to delete controller \'${controller.name}\'?`,
        okText: 'Delete',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(ControllerActions.deleteController({ id: controller.id! }));
      }
    });
  }

  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faTrash = faTrash;
  protected readonly faEdit = faEdit;
}
