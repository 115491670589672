import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Controller, Door } from '@models';

@Injectable({
  providedIn: 'root'
})
export class ControllerService {

  private api = () => {
    return this.appConfigService.apiBaseUrl + `/controllers`;
  }

  constructor(
    private http: HttpClient,
    private appConfigService: ConfigService
  ) { }

  getControllers(): Observable<Controller[]> {
    return this.http.get<Controller[]>(this.api());
  }

  getController(id: number): Observable<Controller> {
    return this.http.get<Controller>(`${this.api()}/${id}`);
  }

  addController(controller: Controller): Observable<Controller> {
    return this.http.post<Controller>(this.api(), controller);
  }

  updateController(controller: Controller): Observable<Controller> {
    return this.http.post<Controller>(`${this.api()}/${controller.id}`, controller);
  }

  updateControllerActivation(id: number): Observable<Controller> {
    return this.http.post<Controller>(`${this.api()}/dsa/${id}/activate`, {});
  }

  deleteController(id: number): Observable<Controller> {
    return this.http.delete<Controller>(`${this.api()}/${id}`);
  }

  getControllerDoors(id: number): Observable<Door[]> {
    return this.http.get<Door[]>(`${this.api()}/${id}/doors`);
  }

  addControllerDoor(id: number, door: Door): Observable<Door[]> {
    return this.http.post<Door[]>(`${this.api()}/${id}/doors`, door);
  }

  deleteControllerDoor(controllerId: number, doorId: number): Observable<Door> {
    return this.http.delete<Door>(`${this.api()}/${controllerId}/doors/${doorId}`);
  }
}
