import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { ThemePalette } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  appTitle = 'Access Control System';
  primaryColor: ThemePalette = 'primary';
  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.setPageTitle();
  }

  setPageTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),  // Track when navigation ends
        map(() => this.activatedRoute),                    // Get the current activated route
        map((route) => {
          while (route.firstChild) route = route.firstChild; // Go through the child routes
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)                    // Get the data property for the title
      )
      .subscribe((data: any) => {
        const pageTitle = data.title ? `${data.title} | ${this.appTitle}` : this.appTitle;
        this.titleService.setTitle(pageTitle);
      });
  }

  isLoginRoute(): boolean {
    return this.router.url.startsWith('/login');
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  protected readonly faSignOutAlt = faSignOutAlt;
}
