<div class="page controllers">
  <ng-container *ngIf="{
        controllers: controllers$ | async,
        loading: isLoading$ | async,
        updatingActivationId: updatingActivationId$ | async
    } as data">
    <div class="container">
      <div class="header">
        <div class="title">Controllers</div>
        <div class="page-actions">
          <button mat-raised-button color="primary" (click)="showManageControllerDialog()">
            <fa-icon class="icon" [icon]="faPlusCircle"></fa-icon>
            <span>New Controller</span>
          </button>
        </div>
      </div>
      <div class="content">
        <div class="loader" *ngIf="data.loading; else controllersData">
          <mat-spinner diameter="50" color="primary"></mat-spinner>
        </div>
        <ng-template #controllersData>
          <div *ngIf="controllers">
            <search-input (searchInputChange)="filterControllers($event)"></search-input>
            <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
                <td mat-cell *matCellDef="let controller">
                  <a [routerLink]="['./', controller.id]">{{ controller.name }}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="serialNumber">
                <th mat-header-cell *matHeaderCellDef>S/N</th>
                <td mat-cell *matCellDef="let controller">{{ controller.serialNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="onlineStatus">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Online
                </th>
                <td mat-cell class="text-center" *matCellDef="let controller">
                  <mat-icon
                    [ngStyle]="{ color: controller.isOnlne ? '#01a901' : '#e36161' }">{{ controller.isOnlne ? 'check_circle' : 'cancel' }}
                  </mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let controller">{{ controller.type }}</td>
              </ng-container>

              <ng-container matColumnDef="addresses">
                <th mat-header-cell *matHeaderCellDef>Address</th>
                <td mat-cell *matCellDef="let controller">
                  <div class="flex flex-col gap-1">
                    <div><span>IP: </span>{{ controller.address }}</div>
                    <div><span>Gateway: </span>{{ controller.gateway }}</div>
                    <div><span>Mask: </span>{{ controller.mask }}</div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="project">
                <th mat-header-cell *matHeaderCellDef>Project</th>
                <td mat-cell *matCellDef="let controller">
                  <empty-icon *ngIf="!controller?.project"></empty-icon>
                  <a [routerLink]="['/projects', controller?.project?.id]">{{ controller?.project?.name }}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="dateAddedToProject">
                <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                    sortActionDescription="Sort by date added to project">Added At
                </th>
                <td mat-cell class="text-center"
                    *matCellDef="let controller">
                  <empty-icon *ngIf="!controller?.dateAddedToProject"></empty-icon>
                  {{ controller?.dateAddedToProject | date: 'MMM dd, YYYY' }}
                </td>
              </ng-container>

              <!-- Notes Column -->
              <ng-container matColumnDef="notes">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Notes</th>
                <td mat-cell class="notes-column text-center" *matCellDef="let controller">
                  <empty-icon *ngIf="!controller.notes?.length"></empty-icon>
                  <div
                    (click)="controller.notesCharLimit = 100 * (+!controller.notesCharLimit)">{{ controller.notes | charLimit: controller.notesCharLimit }}
                    <span *ngIf="controller.notes?.length > 100 && !controller.notesCharLimit">{{ '[-]' }}</span>
                  </div>
                </td>
              </ng-container>

              <!-- Timestamp Created Column -->
              <ng-container matColumnDef="timestampCreated">
                <th mat-header-cell class="text-center" *matHeaderCellDef mat-sort-header
                    sortActionDescription="Sort by datetime created">Created At
                </th>
                <td mat-cell class="text-center"
                    *matCellDef="let controller">{{ controller.timestampCreated | date: 'MMM dd, YYYY HH:mm' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell class="text-center" *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let controller">
                  <div class="flex gap-x-1 text-center items-center">
                    <div class="is-active-toggle" [matTooltip]="controller.isActive ? 'Active' : 'Inactive'">
                      <mat-slide-toggle [ngModel]="controller.isActive"
                                        [disabled]="data.updatingActivationId === controller.id"
                                        (change)="updateIsActive(controller.id)">
<!--                        {{ controller.isActive ? 'Active' : 'Inactive' }}-->
                      </mat-slide-toggle>
                    </div>
                    <div class="text-sm">
                      <button class="icon-button" mat-icon-button matTooltip="Edit"
                              (click)="showManageControllerDialog(controller)">
                        <fa-icon [icon]="faEdit"></fa-icon>
                      </button>
                    </div>
                    <div class="text-sm">
                      <button class="icon-button" mat-icon-button matTooltip="Delete"
                              (click)="showDeleteDialog(controller)">
                        <fa-icon [icon]="faTrash"></fa-icon>
                      </button>
                    </div>
                  </div>
                </td>
              </ng-container>

              <!-- Column definitions -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
                  No Results
                </td>
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>
