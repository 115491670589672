import { Injectable } from '@angular/core';
import { SuccessSnackbarComponent } from '@components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  getEnumValue(enumType: any, key: string): string {
    return enumType[key];
  }

  sortingDataAccessorForNestedProperties(data: any, sortHeaderId: string): string | number {
    const value = sortHeaderId.split('.').reduce((acc: any, key: string) => acc[key], data) as | string | number;
    return typeof value === 'string' ? value.toLowerCase() : value;
  }

  showSuccessSnackbar(message: string): void {
    this.snackBar.openFromComponent(SuccessSnackbarComponent, {
      data: { message: message },
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['success-snackbar'],
    });
  }

  updateParams(filters: Record<string, string | number | string[] | number[] | undefined>) {
    const queryParams: Params = filters;

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
  }

}
