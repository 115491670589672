<div class="page users">
  <ng-container *ngIf="{
        users: users$ | async,
        count: count$ | async,
        loading: isLoading$ | async
    } as data">
    <div class="container">
      <div class="header">
        <div class="title">Users</div>
        <div class="page-actions">
          <button mat-raised-button color="primary" (click)="showManageUserDialog()">
            <fa-icon class="icon" [icon]="faPlusCircle"></fa-icon>
            <span>New User</span>
          </button>
        </div>
      </div>
      <div class="content">
        <search-input [initialValue]="filters.search"
                      (searchInputChange)="updateSearchValue($event)"></search-input>
        <div class="loader" *ngIf="data.loading; else usersData">
          <mat-spinner diameter="50" color="primary"></mat-spinner>
        </div>
        <ng-template #usersData>
          <div *ngIf="data.users">
            <table mat-table [dataSource]="data.users">

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let user">
                  <a [routerLink]="['./', user.id]">{{ user.name }}</a>
                </td>
              </ng-container>

              <ng-container matColumnDef="phone">
                <th class="text-center" mat-header-cell *matHeaderCellDef>Phone</th>
                <td class="text-center" mat-cell *matCellDef="let user">
                  <empty-icon *ngIf="!user.phone"></empty-icon>
                  {{ user.phone }}
                </td>
              </ng-container>

              <ng-container matColumnDef="createdByAccount.username">
                <th mat-header-cell *matHeaderCellDef>
                  Created By
                </th>
                <td mat-cell
                    *matCellDef="let user">{{ (user.createdByAccount?.type == 'PERSON') ? user.createdByAccount?.username : user.createdByAccount?.name }}
                </td>
              </ng-container>

              <!-- Notes Column -->
              <ng-container matColumnDef="notes">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Notes</th>
                <td mat-cell class="notes-column text-center" *matCellDef="let user">
                  <empty-icon *ngIf="!user.notes?.length"></empty-icon>
                  <div
                    (click)="user.notesCharLimit = 100 * (+!user.notesCharLimit)">{{ user.notes | charLimit: user.notesCharLimit }}
                    <span *ngIf="user.notes?.length > 100 && !user.notesCharLimit">{{ '[-]' }}</span>
                  </div>
                </td>
              </ng-container>

              <!-- Timestamp Created Column -->
              <ng-container matColumnDef="timestampCreated">
                <th mat-header-cell class="text-center" *matHeaderCellDef>Created At
                </th>
                <td mat-cell class="text-center"
                    *matCellDef="let user">{{ user.timestampCreated | date: 'MMM dd, YYYY HH:mm' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell class="text-center" *matHeaderCellDef></th>
                <td mat-cell class="flex gap-x-1 text-center" *matCellDef="let user">
                  <div class="text-sm">
                    <button class="icon-button" mat-icon-button title="Edit" (click)="showManageUserDialog(user)">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                  </div>
                  <div class="text-sm">
                    <button class="icon-button" mat-icon-button title="Delete" (click)="showDeleteDialog(user)">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <!-- Column definitions -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
                  No Results
                </td>
              </tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[10, 20, 40, 100]"
                         [pageSize]="filters.pageSize"
                         [pageIndex]="filters.pageOffset"
                         [showFirstLastButtons]="true"
                         [length]="data.count"
                         (page)="onPageFiltersChange($event)"
                         aria-label="Select page of periodic elements">
          </mat-paginator>
        </ng-template>

      </div>
    </div>
  </ng-container>
</div>

