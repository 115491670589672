import { Component, OnInit, ViewChild } from '@angular/core';
import { Project } from '@models';
import { SharedService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  AddProjectDialogComponent,
  ConfirmDialogComponent,
  ConfirmDialogType,
} from '@components';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { map, Observable, tap } from 'rxjs';
import { AppState } from '../../store/store';
import { select, Store } from '@ngrx/store';
import * as ProjectActions from '../../store/actions/project.actions';
import * as ProjectSelectors from '../../store/selectors/project.selectors';

@Component({
  selector: 'projects',
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
})
export class ProjectsComponent implements OnInit {
  projects$?: Observable<Project[] | undefined>;
  isLoading$?: Observable<boolean>;
  projects?: Project[];
  displayedColumns: string[] = ['name', 'createdByAccount.username', 'notes', 'timestampCreated', 'actions'];
  searchValue?: string;
  dataSource = new MatTableDataSource<Project>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.dataSource.sortingDataAccessor = this.sharedService.sortingDataAccessorForNestedProperties;
  };

  constructor(
    private store: Store<AppState>,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions()
  }

  initSubscriptions(): void {
    this.projects$ = this.store.pipe(
      select(ProjectSelectors.selectProjects),
      tap(projects => {
        if (!projects) {
          this.store.dispatch(ProjectActions.loadProjects());
        }
      }),
      map(projects => {
        this.projects = projects?.map(p => ({ ...p, notesCharLimit: 100 }));
        this.dataSource.data = this.projects || [];
        return projects;
      }),
    );
    this.isLoading$ = this.store.pipe(select(ProjectSelectors.selectProjectIsLoading));
  }

  filterProjects(val: string | undefined): void {
    this.searchValue = val;
    if (!val) {
      this.dataSource.data = this.projects || [];
      return;
    }
    this.dataSource.data = this.projects?.filter(p => ['name', 'notes', 'timestampCreated']
      .some(k => p[k as keyof Project]?.toString().toLowerCase().includes(val.toLowerCase())
        || p.createdByAccount?.username?.toLowerCase().includes(val.toLowerCase()))) || [];
  }

  showManageProjectDialog(project?: Project): void {
    this.dialog.open(AddProjectDialogComponent, {
      width: '500px',
      data: { ...project },
    });
  }

  showDeleteDialog(project: Project): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: ConfirmDialogType.DELETE,
        title: 'Delete Project',
        message: `Are you sure you want to delete project \'${project.name}\'?`,
        okText: 'Delete',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(ProjectActions.deleteProject({ id: project.id! }));
      }
    });
  }

  protected readonly faTrash = faTrash;
  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faEdit = faEdit;
}
